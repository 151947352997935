
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const path = window.location.href.replace(
      window.location.origin,
      "https://medium.com/@gyanendraknojiya"
    );
    console.log(path);
    window.location.href = path;
  }, []);
  return <div className="App">Loading...</div>;
}

export default App;
